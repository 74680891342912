import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import kebabCase from "lodash/kebabCase"
import { motion } from "framer-motion"
import styles from "./Filter.module.scss"
import { variantsSecondary } from "../../helpers/motionVariants"

export default props => {
    const data = useStaticQuery(
        graphql`
            query {
                weblogfilter: allMarkdownRemark(
                    limit: 2000
                    filter: {
                        fields: { sourceInstanceName: { eq: "weblog-single" } }
                    }
                ) {
                    group(field: frontmatter___tags) {
                        fieldValue
                        totalCount
                    }
                }
            }
        `
    )
    const listItems = data.weblogfilter
    const ResetFilter = () => (
        <li key="resetFilter" className={styles.filter__list_item}>
            <Link to="/weblog/" className={styles.filter__link}>
                Reset filter
            </Link>
        </li>
    )

    return (
        <motion.nav
            className={styles.filter}
            variants={variantsSecondary}
            initial="hidden"
            animate="enter"
        >
            <span className={styles.filter__title}>Filter Weblog by:</span>
            <ul className={styles.filter__list}>
                {props.resetFilter ? <ResetFilter /> : null}
                {listItems.group.map(tag => (
                    <li
                        key={tag.fieldValue}
                        className={styles.filter__list_item}
                    >
                        <Link
                            to={`/weblog/${kebabCase(tag.fieldValue)}/`}
                            className={styles.filter__link}
                        >
                            #{tag.fieldValue}
                        </Link>
                    </li>
                ))}
            </ul>
        </motion.nav>
    )
}

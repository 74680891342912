import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { motion } from "framer-motion"
import styles from "./WeblogList.module.scss"
import ArrowFull from "../../images/arrow_full.svg"
import arrowStyle from "../Arrow/Arrow.module.scss"
import {
    pagePathBuilder,
    frontmatterPathBuilder,
} from "../../helpers/pathBuilders"
import { variantsTertiary } from "../../helpers/motionVariants"

export default () => {
    const data = useStaticQuery(
        graphql`
            query {
                allMarkdownRemark(
                    filter: {
                        fields: { sourceInstanceName: { eq: "weblog-single" } }
                    }
                    sort: { fields: [frontmatter___date], order: DESC }
                ) {
                    totalCount
                    edges {
                        node {
                            id
                            frontmatter {
                                title
                                pathDirectory
                                pathName
                                date(formatString: "DD MMMM, YYYY")
                            }
                            excerpt(pruneLength: 300, format: HTML)
                        }
                    }
                }
            }
        `
    )

    return (
        <motion.ul
            className={styles.weblog_list}
            variants={variantsTertiary}
            initial="hidden"
            animate="enter"
        >
            {data.allMarkdownRemark.edges.map(({ node }) => (
                <li className={styles.weblog_list_item} key={node.id}>
                    <h2>
                        <Link
                            className={styles.weblog_list_link}
                            to={pagePathBuilder(node)}
                        >
                            {frontmatterPathBuilder(node).title}
                        </Link>
                    </h2>
                    <Link
                        className={styles.weblog_list_date}
                        to={pagePathBuilder(node)}
                    >
                        {frontmatterPathBuilder(node).date}
                    </Link>
                    <div className={`${styles.weblog_list_excerpt} rich_text`}>
                        <span
                            dangerouslySetInnerHTML={{ __html: node.excerpt }}
                        />
                        <Link
                            className={styles.weblog_list_readmore}
                            to={pagePathBuilder(node)}
                        >
                            Read the full post
                            <ArrowFull className={arrowStyle.arrow} />
                        </Link>
                    </div>
                </li>
            ))}
        </motion.ul>
    )
}

import React from "react"
import Metadata from "../components/Metadata/Metadata"
import { graphql } from "gatsby"
import LayoutWeblog from "../layout/LayoutWeblog"
import Heading from "../components/Heading/Heading"
import Filter from "../components/Filter/FilterWeblog"
import WeblogList from "../components/WeblogList/WeblogList"
import { frontmatterPathBuilder } from "../helpers/pathBuilders"

export const query = graphql`
    query {
        markdownRemark(fields: { sourceInstanceName: { eq: "weblog" } }) {
            frontmatter {
                title
                description
            }
        }
    }
`

export default ({ data }) => {
    const page = data.markdownRemark

    return (
        <>
            <Metadata title={page.frontmatter.title} />
            <LayoutWeblog
                heading={
                    <Heading
                        headingLevel="1"
                        headingTitle={frontmatterPathBuilder(page).title}
                        headingDescription={
                            frontmatterPathBuilder(page).description
                        }
                    />
                }
                filter={<Filter />}
                webloglist={<WeblogList />}
            />
        </>
    )
}
